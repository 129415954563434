.shared-components-ui-menu {
    font-size: 1.5rem;
    nav {
        display: flex;
        flex-direction: column;
        padding: 10px;
        margin-right: 20px;
    }

    a {
        display: flex;
        text-decoration: none;
        margin-bottom: 25px;
        align-items: center;
        color: #2D59A0;

        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }

        .icon {
            width: 50px;
            margin-right: 10px;
            text-align: center;

            img {
                display: block;
                margin: auto;
            }
        }
    }
}

@media (max-width: 800px) {
    .shared-components-ui-menu {
        display: none;
    }
}