@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --primary-color: #eda6f6;
    --secondary-color: #1EC7CD; 
    --white-color: #FFF; 
    --blue-color: #7F9FDF;
    --primary-background-gradient: linear-gradient(var(--white-color), var(--secondary-color), var(--primary-color));
    --primary-background-reverse-gradient: linear-gradient(var(--primary-color), var(--secondary-color) 25%);
    --secondary-background-gradient: linear-gradient(var(--blue-color) 80%, var(--primary-color));
    --secondary-background-reverse-gradient: linear-gradient(var(--primary-color), var(--blue-color) 25%);
}

@font-face{
    font-family: "EuclidTriangle";
    src: local("EuclidTriangle"), url("./fonts/EuclidTriangle-Regular.ttf");
}

html, body, #root {
    height: 100%;
    font-size: 62.5%;
}

body {
    background: var(--primary-background-gradient);
}