.app-auth-layout {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    
    > div {
        background: var(--primary-background-reverse-gradient);
        padding: 20px;
        border-radius: 15px;
        width: 90%;
        max-width: 500px;

        .logo {
            display: block;
            width: 70%;
            margin: auto;
        }
    }
}