.user-img {
    cursor: pointer;
    width: 4rem;
    height: 4rem;
    div {
        width: 100%;
        height: 100%;
        background-image: url("../../../../../../public/assets/img/categories/Rectangle.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
    }
}
