.app-layout {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    overflow: hidden;
    font-family: "EuclidTriangle";

    > .wrapper {
        display: flex;
        flex: 1;
        overflow: hidden;

        > .content-wrapper {
            display: flex;
            flex-direction: column;
            flex: 1;
            background: var(--secondary-background-gradient);
            padding: 10px;
            border-radius: 20px 0px 0px 0px;
            box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
                rgba(0, 0, 0, 0.23) 0px 6px 6px;
            overflow: hidden;

            > .content-section {
                flex: 1;
                overflow-y: auto;
                display: flex;
                justify-content: center;
                > div {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    text-shadow: 1px 2px 8px #1f0f0d;
                    color: white;
                    height: 100%;                    
                    max-width: 1050px;                    
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .app-layout {
        > .wrapper > .content-wrapper {
            border-radius: 20px 20px 20px 20px;
            margin: 15px;
            background: var(--secondary-background-reverse-gradient);
            > .content-section{
                > div{
                    margin: 0;
                }
            }
        }
    }
}
