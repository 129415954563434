.shared-components-ui-mobilemenu {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    max-width: 400px;
    width: 90%;
    align-items: center;
    margin: auto;

    .icon:hover {
        cursor: pointer;
        opacity: 0.6;
    }

    img {
        width: 30px;
        height: 30px;
    }
}

@media (max-width: 800px) {
    .shared-components-ui-mobilemenu {
        display: flex;
    }
}