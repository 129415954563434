
.component-dialog {
    .popup {
        font-size: 18px;
        background-color: #222;
        color: #fff;
    }

    .btn-primary {
        background-color: var(--primary-color);
        color: #fff;
    }
}

.component-dialog .swal2-icon,
.component-dialog .swal2-icon * {
    font-size: 12px !important;
}