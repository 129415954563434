.shared-components-ui-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    width: 95%;
    margin: auto;
    max-width: 1900px;

    > .right-side {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 30%;

        > .search-button {
            width: 80%;
            height: 100%;
            form {
                position: relative;
                transition: all 1s;
                width: 100%;
                height: 40px;
                background: #ccdef2;
                box-sizing: border-box;
                border-radius: 25px;
                border: 4px solid #ccdef2;
                padding: 5px;
                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 90%;
                    height: 32.5px;
                    outline: 0;
                    border: 0;
                    background-color: transparent;
                    font-size: 15px;
                    border-radius: 20px;
                    padding: 0 20px;
                }
                .fa {
                    box-sizing: border-box;
                    padding: 7px;
                    width: 32.5px;
                    height: 32.5px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    border-radius: 50%;
                    background-color: var(--primary-color);
                    color: white;
                    text-align: center;
                    font-size: 1.2em;
                    transition: all 1s;
                    -webkit-transition: all 1s;
                    -moz-transition: all 1s;
                    -ms-transition: all 1s;
                    -o-transition: all 1s;
                    svg {                        
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        > .logout {
            font-size: 3rem;
            color: var(--primary-color);
            cursor: pointer;
        }
    }

    img {
        height: 85px;
    }

    .image {
        width: 50px;
        height: 50px;
        background-color: var(--blue-color);
        border-radius: 100%;
    }
}

@media (max-width: 800px) {
    .shared-components-ui-header {
        padding-top: 20px;

        > .right-side {
            width: 70%;
        }

        img {
            height: 40px;
        }

        .image {
            width: 40px;
            height: 40px;
        }
    }
}
